jQuery('.search-markers').on("click", function(e) {
  setTimeout(function() {
    jQuery('input.facetwp-search').focus();
  }, 300);
});




/*
 *  document ready
 *
 *  This function will render each map when the document is ready (page has loaded)
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	5.0.0
 *
 *  @param	n/a
 *  @return	n/a
 */
// global var


jQuery(document).ready(function() {

  jQuery(document).on('facetwp-loaded', function() {

    //jQuery('#filters-modal').collapse();
    results_ct = FWP.settings.pager.total_rows;

    // Update results count.
    jQuery('#view-results-trigger').html('View <span>' + results_ct + '</span> results');

    if ('' == FWP.build_query_string()) {
      jQuery('.reset-button').addClass('disabled');
      jQuery('.reset-button').attr('disabled', 'disabled');
    } else {
      jQuery('.reset-button').removeClass('disabled');
      jQuery('.reset-button').removeAttr('disabled', 'disabled');
    }

    if (FWP.facets.program !== undefined && FWP.facets.program.length > 0) {
      var program_slug = FWP.facets.program[0];

      // Get the program name from the select list, given the slug
      var program_name = jQuery('.facetwp-facet-program select.facetwp-dropdown').find(":selected").text()
      //program_name = program_name.replace('\(([^\)]+)\)');

      program_name = program_name.replace(/{{(.+?)\}}/g, '');

      jQuery('.current-program-title').html(program_name);
    } else {
      jQuery('.current-program-title').html('Historic Markers');
    }



  });

  jQuery(document).on('facetwp-refresh', function() {
    jQuery('.filters-overlay').addClass('loading');
  });

  var clicked_marker = '';
  jQuery(document).on('facetwp-loaded', function() {
    jQuery('.filters-overlay').removeClass('loading');




    //  google.maps.event.addListener(FWP_MAP.map, "click", function(event) {
    //    var markers = FWP_MAP.markersArray;
    //    for (var i = 0; i < markers.length; i++) {
    //      markers[i].infowindow.close();
    //  }
    //});


    if (typeof(FWP_MAP) !== 'undefined') {
      var markers = FWP_MAP.markersArray;
      // When a marker is clicked, get some info about the marker.
      for (var i = 0; i < markers.length; i++) {
        //console.log(markers[i]);

        function getMarkerInfo() {
          clicked_marker = this;
          var str = this.content // your HTML string
          var doc = new DOMParser().parseFromString(str, "text/html");
          var elem = doc.querySelector(".mi");

          var myarr = elem.id.split("-");
          var post_id = myarr[1];

          this.info.setContent('<div class="mi"><div class="loader"></div></div>');

          jQuery.ajax({
            type: "post",
            dataType: "json",
            url: vars.ajaxurl,
            data: {
              action: "lab_load_marker_info",
              post_id: post_id,
            },
            success: function(response) {
              if (response) {
                var marker_info = response.html;
                clicked_marker.info.setContent('<div class="mi" id="' + elem.id + '">' + marker_info + '</div>');
              } else {
                console.log('Loading of marker desciption failed.');
              }
            },
            error: function(errorThrown) {
              console.log('ERROR: ' + errorThrown);
            }
          });
        }

        google.maps.event.addListener(markers[i], "click", getMarkerInfo);
      }

    }
  });



  jQuery('#filters-modal').on('show.bs.collapse', function() {
    jQuery('.filters-overlay').addClass('showing');
  })
  jQuery('#filters-modal').on('shown.bs.collapse', function() {
    jQuery('.filters-overlay').addClass('show');
  })

  jQuery('#filters-modal').on('hide.bs.collapse', function() {
    jQuery('.filters-overlay').removeClass('show');
  })
  jQuery('#filters-modal').on('hidden.bs.collapse', function() {
    jQuery('.filters-overlay').removeClass('showing');
  })



  jQuery(document).click(function(event) {
    if (
      !jQuery(event.target).closest('#filters-modal').length &&
      !jQuery(event.target).is('#filters-modal') &&
      !jQuery(event.target).is('.fs-dropdown') &&
      !jQuery(event.target).is('.fs-option') &&
      !jQuery(event.target).is('.fs-options') &&
      !jQuery(event.target).is('.facetwp-counter') &&
      !jQuery(event.target).is('.facetwp-type-autocomplete') &&
      !jQuery(event.target).is('.autocomplete-suggestion') &&
      !jQuery(event.target).is('.autocomplete-suggestions') &&
      !jQuery(event.target).is('.autocomplete-selected') &&
      !jQuery(event.target).is('.facetwp-autocomplete-update') &&
      !jQuery(event.target).is('.fs-option-label')
    ) {
      if (jQuery("#filters-modal").hasClass('show')) {
        jQuery('#filters-modal').collapse('hide');
      }
    }
  });









  //function setPositionAsContent() {
  //  infoWindow.setContent(this.position.toString());
  //  infoWindow.open(this.map, this);
  //  console.log('HERE');
  //}

  //google.maps.event.addListener(marker, "click", setPositionAsContent);



});









/*
 *  add_marker
 *
 *  This function will add a marker to the selected Google Map
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	$marker (jQuery element)
 *  @param	map (Google Map object)
 *  @return	n/a
 */

function add_marker($marker, map) {

  // var
  var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

  // create marker
  var marker = new google.maps.Marker({
    position: latlng,
    map: map,
    optimized: false,
    zIndex: 99999999
  });

  // add to array
  map.markers.push(marker);

  // if marker contains HTML, add it to an infoWindow
  if ($marker.html()) {
    // create info window
    var infowindow = new google.maps.InfoWindow({
      content: $marker.html()
    });

    infowindow.setZIndex(9999);

    marker.infowindow = infowindow;

    // show info window when marker is clicked
    google.maps.event.addListener(marker, 'click', function() {

      // Close all other markers
      jQuery.each(map.markers, function(i, marker) {
        marker.infowindow.close();
      });

      infowindow.open(map, marker);


    });
  }

}





/*
 *  center_map
 *
 *  This function will center the map, showing all markers attached to this map
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	map (Google Map object)
 *  @return	n/a
 */

function center_map(map) {

  // vars
  var bounds = new google.maps.LatLngBounds();
  var count = 1;
  var added_time = 0;
  var x = 0;
  var duration = 2000;
  var start_time = 700;
  var marker_ct = map.markers.length;

  var steps = Math.round(duration / marker_ct);

  // loop through all markers and create bounds
  jQuery.each(map.markers, function(i, marker) {

    var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

    bounds.extend(latlng);
    marker.setVisible(false);

    /*
    if (count === 1) {
      start_time = 500;
    }
    if (count > 1) {
      x = Math.ceil();
      added_time = parseInt(Math.log(count)*Math.log(count)*150, 10);
      start_time = 700;
    }
    */

    added_time = count * 15;



    setTimeout(function() {
      marker.setVisible(true);
      marker.setAnimation(google.maps.Animation.DROP);
    }, start_time + added_time);

    count++;

  });

  // only 1 marker?
  if (map.markers.length === 1) {
    // set center of map
    map.setCenter(bounds.getCenter());
    map.setZoom(11);
  } else {
    // fit to bounds
    map.setCenter(new google.maps.LatLng(40.674719, -73.949727));

    if (jQuery(document).width() < 1000) {
      map.setZoom(12);
    } else {
      map.setZoom(13);
    }
  }

}

/*
 *  new_map
 *
 *  This function will render a Google Map onto the selected jQuery element
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	$el (jQuery element)
 *  @return	n/a
 */

function new_map($el) {

  // var
  var $markers = $el.find('.marker');


  // vars
  var args = {
    zoom: 11,
    scrollwheel: false,
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControl: false
  };


  // create map
  var map = new google.maps.Map($el[0], args);


  map.set('styles', [{
        "featureType": "landscape",
        "stylers": [{
            "hue": "#FFBB00"
          },
          {
            "saturation": 43.400000000000006
          },
          {
            "lightness": 37.599999999999994
          },
          {
            "gamma": 1
          }
        ]
      },
      {
        "featureType": "road.highway",
        "stylers": [{
            "hue": "#FFC200"
          },
          {
            "saturation": -61.8
          },
          {
            "lightness": 45.599999999999994
          },
          {
            "gamma": 1
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "stylers": [{
            "hue": "#FF0300"
          },
          {
            "saturation": -100
          },
          {
            "lightness": 51.19999999999999
          },
          {
            "gamma": 1
          }
        ]
      },
      {
        "featureType": "road.local",
        "stylers": [{
            "hue": "#FF0300"
          },
          {
            "saturation": -100
          },
          {
            "lightness": 52
          },
          {
            "gamma": 1
          }
        ]
      },
      {
        "featureType": "water",
        "stylers": [{
            "hue": "#0078FF"
          },
          {
            "saturation": -13.200000000000003
          },
          {
            "lightness": 2.4000000000000057
          },
          {
            "gamma": 1
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [{
            "hue": "#00FF6A"
          },
          {
            "saturation": -1.0989010989011234
          },
          {
            "lightness": 11.200000000000017
          },
          {
            "gamma": 1
          }
        ]
      }
    ]

  );

  // add a markers reference
  map.markers = [];


  // add markers
  $markers.each(function() {

    add_marker(jQuery(this), map);

  });

  // center map
  center_map(map);
  jQuery('.acf-map').addClass('inplace');

  // return
  return map;

}





jQuery(document).ready(function() {

  jQuery('.acf-map').each(function() {

    // create map
    map = new_map(jQuery(this));

    google.maps.event.addListener(map, "click", function(event) {
      jQuery.each(map.markers, function(i, marker) {
        marker.infowindow.close();
      });
    });

    // Open single marker.
    //if (jQuery('.acf-map.single').length) {
    //jQuery.each(map.markers, function(i, marker) {
    //  marker.infowindow.open(map, marker);
    //});
    //}

  });

});